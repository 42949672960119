<template>
  <div class="warehouseManage">
    <PageHeader title="仓库管理" />
    <div class="searchView flex a-center m-tb-10 j-between">
      <div class="out p-lr-10 flex a-center m-left-20 ModelBttton-white-30" @click="() => $refs.FieldManagement.openSetFieldManagement()">
        <i class="el-icon-setting f14 p-right-5" />   字段管理
      </div>
      <div class="flex m-left-20 SearchBox_30" style="flex: 1">
        <el-input
          v-model="searchForm.KeyWords"
          size="medium"
          placeholder="可通过仓库名称、仓库编号、仓库负责人搜索"
          class="rule-input-edit"
        >
          <template slot="prepend">
            <i class="el-icon-search" />
          </template>
          <div
            slot="append"
            class="cursor select_none"
            style="color: rgb(58, 120, 241)"
            @click="search"
          >
            搜 索
          </div>
        </el-input>
        <div v-if="$minCommon.checkMeau('ckglmanageadd', userBottons)" class="out p-lr-10 flex a-center m-right-10">
          <el-button
            class="ModelBttton-blue-30"
            round
            type="primary"
            icon="el-icon-s-fold"
            @click="creatArdess"
          >新建仓库</el-button>
        </div>
      </div>
    </div>
    <div class="p-lr-20">
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        height="calc(100vh - 325px)"
        :header-cell-style="headerCStyle"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        row-key="IDX"
        border
      >
        <template v-for="(item,index) in tableListJson.warehouseManage">
          <af-table-column
            v-if="item.isChecked"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            show-overflow-tooltip
            align="center"
          />
        </template>
        <el-table-column prop="set" align="center" width="50" label="操作">
          <!--  slot-scope="scope" -->
          <template slot-scope="scope">
            <el-popover
              placement="left"
              trigger="hover"
              popper-class="test_pop_view"
            >
              <div class="DropDownList">
                <div v-if="$minCommon.checkMeau('ckglmanageedit', userBottons)" class="OperationButton">
                  <el-link :underline="false" @click="edit(scope.row)"><i class="el-icon-edit  p-right-5" />编辑</el-link>
                </div>
                <div v-if="$minCommon.checkMeau('ckglmanagedelete', userBottons)" class="OperationButton">
                  <el-link :underline="false" @click="DeleteStockCode(scope.row.IDX)"><i class="el-icon-delete  p-right-5" />删除</el-link>
                </div>
              </div>
              <i slot="reference" class="el-icon-more" />
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          :current-page.sync="currentPage"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </div>
    <!-- 新建仓库 start -->
    <el-dialog class="DepartmentBox" top="5vh" width="600px!important" :visible.sync="showSetAddress" custom-class="dialog_view" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">{{ ruleForm.IDX ?'编辑仓库' :'创建仓库' }}</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => showSetAddress = false" />
      </div>
      <div class="m-top-20" />
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="90px" class="demo-ruleForm">
        <el-form-item label="仓库名称" prop="StockName">
          <el-input v-model="ruleForm.StockName" size="mini" />
        </el-form-item>
        <el-form-item label="仓库负责人" prop="ResponsibilityUser">
          <el-input v-model="ruleForm.ResponsibilityUser" size="mini" />
        </el-form-item>
        <el-form-item label="仓库编号" prop="StockCode">
          <el-input v-model="ruleForm.StockCode" size="mini" />
        </el-form-item>

        <el-form-item label="仓库类型" prop="StockType" class="MultiSelectBox">
          <div class="flex a-center" style="height:30px">
            <el-radio v-model="ruleForm.StockType" label="常温仓">常温仓</el-radio>
            <el-radio v-model="ruleForm.StockType" label="冷藏仓">冷藏仓</el-radio>
            <el-radio v-model="ruleForm.StockType" label="冷冻仓">冷冻仓</el-radio>
            <el-radio v-model="ruleForm.StockType" label="药品仓">药品仓</el-radio>
            <el-radio v-model="ruleForm.StockType" label="other">其他</el-radio>
            <el-input v-if="ruleForm.StockType === 'other'" v-model="typeOtherName" size="mini" type="text" />
          </div>
        </el-form-item>

        <el-form-item label="备注" prop="Remark">
          <el-input v-model="ruleForm.Remark" size="mini" />
        </el-form-item>
        <el-form-item label="是否默认" prop="isDefault">
          <el-checkbox v-model="ruleForm.isDefault" />
        </el-form-item>
        <div class="caozuo p-tb-20 t-right t_line_s">
          <el-button @click="() => showSetAddress = false">取消</el-button>
          <el-button :loading="loading" type="primary" @click="submitAddress('ruleForm')">{{ ruleForm.IDX ?'确认' :'创建仓库' }} </el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 新建仓库 end-->
    <!-- 字段搜索 -->
    <FieldManagement ref="FieldManagement" action-name="GetStockCodeList" :list="tableListJson.warehouseManage" name="warehouseManage" :change-list-table="changeListTable" />
  </div>
</template>

<script>
import Index from '@/minxin/system/warehouseManage'
export default Index
</script>
<style lang="scss" scoped>
// @import "@/style/search.scss";
 .rule-input-edit ::v-deep {
    .el-input-group__prepend {
      background-color: #fff !important;
      border-radius: 20px 0 0 20px !important;
    }
    .el-input-group__append {
      background-color: #fff !important;
      border-radius: 0 20px 20px 0 !important;
    }
  }
   .PageHeader1{
    width: 100%;
    height: 50px;
    background-color: #fff;
    position: absolute;
    left: 0;
    top:0;
    display: flex;
    color:   #006fda;
    &::after{
        content: '';
        width: 3px;
        height:50%;
        background-color:  #006fda;
        position: absolute;
        left: 0;
        top: 25%;
    }
 }
</style>
