import PageHeader from '@/components/PageHeader'
import tableMixin from '@/minxin/table-minxin'
import root from '@/minxin/root'

const Index = {
  name: 'warehouseManage',
  mixins: [tableMixin, root],
  data() {
    return {
      root_name: 'ckglmanage',
      root_msg: '仓库管理',
      showSetAddress: false,
      rules: {
        StockName: [
          { required: true, message: '请输入仓库名称', trigger: 'blur' }
        ],
        StockCode: [
          { required: true, message: '请输入仓库编号', trigger: 'blur' }
        ]
      },
      typeOtherName: '',
      ruleForm: {
        IDX: '',
        CompanyID: '',
        Remark: '',
        StockName: '',
        ResponsibilityUser: '',
        StockCode: '',
        StockType: ''
      },
      ruleForm_temp: {
        IDX: '',
        CompanyID: '',
        Remark: '',
        StockName: '',
        ResponsibilityUser: '',
        StockCode: '',
        StockType: ''
      },
      searchForm: {
        KeyWords: '',
        PageIndex: 1,
        PageSize: 15
      },
      loading: false
    }
  },
  components: {
    PageHeader
  },
  methods: {
    // 搜索
    search() {
      this.getTableDataAsync()
    },
    // 新建仓库
    creatArdess() {
      this.showSetAddress = true
      this.$refs.ruleForm.resetFields()
      this.ruleForm = this.ruleForm_temp
    },
    // 编辑仓库库
    edit(row) {
      for (const key in row) {
        this.ruleForm[key] = row[key]
      }
      const arr = ['常温仓', '冷藏仓', '冷冻仓', '药品仓']
      const result = arr.filter(item => item === this.ruleForm.StockType)
      if (result.length <= 0) {
        // 其他自定义类型
        this.typeOtherName = this.ruleForm.StockType
        this.ruleForm.StockType = 'other'
      }
      console.log(this.ruleForm)
      this.showSetAddress = true
    },
    // 删除仓库
    async  DeleteStockCode(IDX) {
      let msg = '此操作将永久删除仓库'
      const result = await this.$api.CheckStockCodeMaterial({
        IDX
      })
      if (result.RetCode !== '0') return this.$message.error('删除失败:' + result.RetMsg)
      if (result.Message === '1') {
        msg = '仓库中含有物料, 是否确认删除'
      }
      this.$confirm(msg, '是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.DeleteStockCode({ IDX }).then(res => {
          if (res.RetCode !== '0') return this.$message.error('删除失败:' + res.RetMsg)
          this.refreshCurrentChange()
          return this.$message.success('删除成功')
        })
      }).catch(() => {
      })
    },
    // 新增地址/ 编辑
    submitAddress(formName) {
      this.loading = true
      console.log('this.ruleForm', this.ruleForm)
      if (this.ruleForm.StockType === 'other') {
        this.ruleForm.StockType = this.typeOtherName
      }
      this.$refs[formName].validate((valid) => {
        // this.$refs[formName].resetFields()
        if (!valid) return false
        this.$api.SaveStockInfo(this.ruleForm).then(res => {
          console.log(res)
          this.loading = false
          if (res.RetCode !== '0') return this.$message.error('请求失败:' + res.RetMsg)
          this.$message.success(this.ruleForm.IDX ? '编辑成功' : '保存成功')
          this.refreshCurrentChange()
          this.showSetAddress = false
          this.$refs[formName].resetFields() // 清空表单
          this.ruleForm.IDX = ''
          this.typeOtherName = '' // 其他仓库名称清空
        }).catch((err) => {
          this.$message.error('请求失败:' + err)
          return this.loading = false
        })
      })
    },
    // 复制到页面上实现获取数据逻辑
    async getTableDataAsync(page, pageSize) {
      this.searchForm.PageIndex = page || 1
      this.searchForm.PageSize = this.tablePageSize
      const response = await this.$api.GetStockCodeList(this.searchForm)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      console.log('response', response)
      return response
    }
  }
}

export default Index
